<template>
  <div class="courses">
    <transition name="component-fade" mode="out-in">
      <div key="loading" v-if="loading" class="courses__loader">
        <div class="container f-center">
          <Loader />
        </div>
      </div>
      <div key="content" v-else-if="courses.length" class="courses__wrapper">
        <div class="courses__info-box info-box container">
          <div class="info-box__title">{{ $t('header.myCourses') }}</div>
          <div class="info-box__description">
            {{ $t('supportText.selectACourseToEdit') }}
          </div>
        </div>
        <div class="container">
          <div class="courses__action">
            <AddButtonWithIcon :title="$t('buttonLabels.createCourse')" @click="createCourse" />
          </div>

          <div class="courses__content">
            <ContentList
              class="w-100"
              :list="courses"
              :disableDragAndDrop="true"
              :routerPush="routerPush"
              @remove="showModalRemove"
              :showDropDown="false"
            />
          </div>
        </div>
      </div>
      <div key="empty-state" v-else>
        <div class="container">
          <div class="empty-state__title">{{ $t('header.createFirstCourse') }}</div>
          <div class="empty-state__images images">
            <div class="images__wrapper">
              <div class="image">
                <img src="@/assets/images/svg/bookshell.svg" alt="Доска" />
                <div class="image__text">{{ $t('supportText.addHelpfulMaterials') }}</div>
              </div>
              <div class="image">
                <img src="@/assets/images/svg/user.svg" alt="Книжная полка" />
                <div class="image__text">{{ $t('supportText.inviteUsersForTraining') }}</div>
              </div>
              <div class="image">
                <img src="@/assets/images/svg/bagpack.svg" alt="Пользователь" />
                <div class="image__text">{{ $t('supportText.combineCoursesToPrograms') }}</div>
              </div>
            </div>
          </div>
          <div class="empty-state__action">
            <AddButtonWithIcon :title="$t('buttonLabels.createCourse')" @click="createCourse" />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import ContentList from '@/components/ContentList.vue';
// eslint-disable-next-line import/no-cycle
import ActivityService from '@/services/activity.service';
import AddButtonWithIcon from '@/components/Buttons/AddButtonWithIcon.vue';
import Loader from '@/components/Loader/Loader.vue';

export default {
  name: 'Courses',
  data: () => ({
    courses: [],
    loading: true,
  }),
  components: {
    Loader,
    ContentList,
    AddButtonWithIcon,
  },
  created() {
    ActivityService.getCourses()
      .then(({ data }) => {
        this.courses = data ?? [];
      })
      .catch(console.log)
      .finally(() => (this.loading = false));
  },
  methods: {
    createCourse() {
      ActivityService.createCourse()
        .then(({ data: _id }) => {
          this.$router.push(`/course/self/${_id}`);
        })
        .catch(console.log);
    },
    routerPush(item) {
      this.$router.push(`/course/self/${item._id}`);
    },
    showModalRemove(id) {
      this.$bvModal
        .msgBoxConfirm(this.$t('supportText.thisActionCannotBeUndone'), {
          size: 'sm',
          okVariant: 'secondary',
          cancelVariant: 'primary',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'lms-modal__footer border-top-0',
          centered: true,
          bodyClass: 'lms-modal__body',
          okTitle: this.$t('buttonLabels.delete'),
          cancelTitle: this.$t('buttonLabels.cancel'),
        })
        .then((isConfirmed) => ({
          isConfirmed,
          id,
        }))
        .then(this.remove)
        .catch(console.log);
    },
    remove({ isConfirmed, id }) {
      if (!isConfirmed) {
        return;
      }
      ActivityService.deleteActivity(id)
        .then(() => {
          this.courses = this.courses.filter((course) => course._id !== id);
        })
        .catch(console.log);
    },
  },
};
</script>
